import React from 'react'

const PlayIconSvg = () => {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      className="bi bi-play-circle-fill"
      viewBox="0 0 16 16"
      fill='#fff'
    >
      <path d="M16 8A8 8 0 110 8a8 8 0 0116 0zM6.79 5.093A.5.5 0 006 5.5v5a.5.5 0 00.79.407l3.5-2.5a.5.5 0 000-.814l-3.5-2.5z"></path>
    </svg>
      );
}

export default PlayIconSvg