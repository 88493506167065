import axios from 'axios';

const GOOGLE_API_KEY = "AIzaSyDSrNgyDHSkqDzUMNIJEbsLt3u_q1RbjYw";


export const getLatLngArray = (data) => {
  const locationSteps = data.map((direction) => ({
    lat: direction.start_location.lat,
    lng: direction.start_location.lng,
  }));
  const lastIndexValue = data[data.length - 1];
  locationSteps.push({
    lat: lastIndexValue.end_location.lat,
    lng: lastIndexValue.end_location.lng,
  });
  return locationSteps;
};

export const getNextDirection = (response) =>{
  const distance = response.data.data.routes[0].legs[0].distance.value
  console.log('response', )
  return distance
}

export const fetchPincode = async (lat, lng) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`
    );

    if (response.data.results.length > 0) {
      const addressComponents = response.data.results[0].address_components;
      const postalCodeComponent = addressComponents.find(component =>
        component.types.includes('postal_code')
      );
      return postalCodeComponent ? postalCodeComponent.long_name : 'Pincode not found';
    } else {
      return 'Pincode not found';
    }
  } catch (error) {
    console.error('Error fetching pincode:', error);
    return 'Error fetching pincode';
  }
};