import { createSlice } from "@reduxjs/toolkit";

const setToLocalStorage = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
};

const getFromLocalStorage = (name) => {
  return JSON.parse(localStorage.getItem(name));
};

const initialState = {
  menuDetails: {},
  menuLoaded: false,
  dishes: [],
  dishesLoaded: false,
  noDishes: false,
  numberOfMenus: 0,
  demoForUnlockMenu: getFromLocalStorage("tourCompletedForUnlockMenu") || false,
  demoForCheckPrice: getFromLocalStorage("tourCompletedForCheckPrice") || false,
  isMenuClicked: false,
  currentChefIdClicked: null,
  chefInfoDefaultKey: 1
};

const menuSlice = createSlice({
  name: "Menu",
  initialState,
  reducers: {
    isMenuDetailsLoading(state, action) {
      state.menuLoaded = false;
    },
    replaceMenuDetails(state, action) {
      state.menuDetails = action.payload.menuDetails;
      state.menuLoaded = true;
      state.noDishes = false;
    },
    setCurrentChefIdClicked(state, action) {
      state.currentChefIdClicked = action.payload;
    },
    setChefInfoDefaultKey(state, action) {
      state.chefInfoDefaultKey = action.payload;
    },
    replaceDishes(state, action) {
      state.dishes = action.payload.dishes;
      state.dishesLoaded = true;
      state.noDishes = false;
      state.numberOfMenus++;
    },
    setNoDishes(state, action) {
      state.noDishes = true;
      state.dishesLoaded = true;
    },
    setDemoForUnlockMenu(state) {
      state.demoForUnlockMenu = false;
      setToLocalStorage("tourCompletedForUnlockMenu", true);
    },
    setDemoForCheckPrice(state) {
      state.demoForCheckPrice = false;
      setToLocalStorage("tourCompletedForCheckPrice", true);
    },
    setIsMenuClicked(state, { payload }) {
      state.isMenuClicked = payload;
    },
  },
});

export const menuActions = menuSlice.actions;

export default menuSlice;
