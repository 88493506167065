import React, { useEffect, Suspense, useLayoutEffect, useState } from "react";
import BottomBar from "../BottomBar";
import Dish from "../menu/Dish";
import cartBg from "../../assets/cartBg.png";
import browseLogo from "../../assets/browseLogo.png";
import MenuTopBar from "../menu/MenuTopBar";
import AppContainer from "../util/AppContainer";
import { useSelector, useDispatch } from "react-redux";
import "./helpChatScreen.css";
import { S3_MENU_THUMBNAIL_FOLDER } from "../../store/constants";
import { SuspenseImg } from "../util/SuspendImg";
import { bottomBarActions } from "../../store/bottomBarSlice";
import ImageLoader from "../chef/ImageLoader";
import "../menu/browse-menu-button.css";
import ReactGA from "react-ga4";
import { getQueryResponse, setBookingData } from "../../store/loginActions";
import { loginActions } from "../../store/loginSlice";
import { Button, Form, Input } from "antd";
import RightOutlinedSvg from "../../assets/ReactSvg/RightOutlinedSvg";
import DefaultReplyArrowSvg from "../../assets/ReactSvg/DefaultReplyArrowSvg";

const HelpChatScreen = () => {
  const [form] = Form.useForm();
  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/cart" });
    ReactGA.event({
      category: "tab",
      action: "helpChatScreen",
    });
  }, []);
  const [selectedQuery, setSelectedQuery] = useState("")
  const user = useSelector(({ auth }) => auth.user);
  const queryResponse = useSelector((state) => state.auth.queryResponse);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBookingData(user.userid));
  }, [dispatch, user.userid]);

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const onBackClick = () => {
    switchTab("dishBook");
  };

  const handleSetSelectedQueryClick = (query) => {
    setSelectedQuery(query)
    dispatch(loginActions.setQueryResponse({ type: "SENT", text: query }));
    const payload = {
      "queryText": query
    }
    dispatch(getQueryResponse(payload));
  }

  const onFinish = (values) => {
    if (!!values.queryText) {
      handleSetSelectedQueryClick(values.queryText);
      form.resetFields();
    }
  };

  return (
    <AppContainer>
      <div style={{ height: "-webkit-fill-available" }} className="cart-wrapper">
        <div className="cart-top-bar">
          <MenuTopBar
            name="Help & Support"
            cuisine=""
            notMenu={true}
            onBackClick={onBackClick}
          />
        </div>
        {!selectedQuery ? <section style={{ height: "100%", background: "#f4f4f5" }} className="section-food-accordian">
          <div style={{ marginTop: 20, background: "#fff" }}>
            <div onClick={() => handleSetSelectedQueryClick("Payment and billing related query")} className="query-button-container">
              Payment and billing related query
            </div>
            <div onClick={() => handleSetSelectedQueryClick("Payment and billing related query")} className="query-button-container">
              Payment and billing related query
            </div>
            <div onClick={() => handleSetSelectedQueryClick("Payment and billing related query")} className="query-button-container">
              Payment and billing related query
            </div>
            <div onClick={() => handleSetSelectedQueryClick("Payment and billing related query")} className="query-button-container">
              Payment and billing related query
            </div>
          </div>
        </section> : <section style={{ height: "100%", background: "#ffffff" }} className="section-food-accordian">
          <div className="message-list-wrapper-container">
            <div className="message-list-wrapper">
              {queryResponse.map((chat) => chat.type === "SENT" ? <div className="single-message-list-wrapper">
                <div className="single-message-chatbox">
                  <div className="single-message-chatbox-radius">{chat.text}</div>
                </div>
              </div> : <div className="single-message-list-wrapper">
                <div className="single-message-chatbox-reply">
                  <div className="single-message-chatbox-radius-reply">{chat.text}</div>
                </div>
              </div>)}
              {queryResponse.length > 1 && <div className="single-message-list-wrapper">
                <div className="single-message-chatbox-reply">
                  <div onClick={() => handleSetSelectedQueryClick("Where is my order?")} style={{color:"#0000EE"}} className="single-message-chatbox-radius-reply"> <DefaultReplyArrowSvg />Where is my order?</div>
                </div>
              </div>}
            </div>
            <div className="chatInputAreaWrapper">
              <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: "100%" }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
                className="chatInputAreaForm"
              >
                <Form.Item
                  name="queryText"
                >
                  <Input className="chatInputArea" placeholder="Enter query" />
                </Form.Item>
                <Form.Item>
                  <Button className="supportChatButton" htmlType="submit" type="primary" shape="circle">
                    <RightOutlinedSvg />
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </section>}
      </div>
      <BottomBar />
    </AppContainer>
  );
};

export default HelpChatScreen;
