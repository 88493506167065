import React, { useState, useEffect, useRef } from 'react'
import dashjs from "dashjs";
import ControlBar from '../explore/ControlBar';
import { Player } from 'video-react';

const CustomVideoPlayer = ({ videoUrl, index, onReady, handleSetIsVideoReadyToPlay, isPlaying: isPlayingVideo, seekTimeRef, seekTime, setSeekTime }) => {
  const playerRef = useRef(null);
  const [isPlaying, setPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [isPlayingBtn, setIsPlayingBtn] = useState(true);
  const [isVideoReady, setIsVideoReady] = useState(false);

  useEffect(() => {
    setPlaying(isPlayingVideo);
  }, [isPlayingVideo]);



  const onMuteClick = () => {
    setIsMuted(prev => !prev);
  };

  const handleSetIsPlayingBtn = () => {
    setIsPlayingBtn(prev => !prev);
  };

  const handleReady = () => {
    setIsVideoReady(true);
    onReady();
  };

  useEffect(() => {
    handleReady()
  }, [])

  const src = videoUrl;

  const handlePlay = () => {
    setTimeout(() => {
      handleSetIsVideoReadyToPlay(true);
    }, 100);
  };

  useEffect(() => {
    const player = playerRef.current;

    const handleReadyStateChange = () => {
      handlePlay()
    };

    const handleTimeUpdate = () => {
      if (player.currentTime >= 2) {
        player.pause();
      }
    };

    const videoElement = player.video.video;

    videoElement.addEventListener('loadeddata', handleReadyStateChange);
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('loadeddata', handleReadyStateChange);
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []);

  return (
    <div>
      <div style={{ position: "relative" }} className='player-wrapper'>
        <div class="explore-video-player">
          <div class="videoContainer" id="videoContainer">
            <Player
              ref={playerRef}
              src={src}
              autoPlay={true}
              width="100%"
              autoplay
              muted
              loop={true}
              playsInline
              // onLoadStart={handlePlay}
            >
            </Player>
            <div id="videoController" class="video-controller unselectable">
              <div id="playPauseBtn" class="btn-play-pause" title="Play/Pause">
                <span id="iconPlayPause" class="icon-play"></span>
              </div>
              <span id="videoTime" class="time-display">
                00:00:00
              </span>
              <div
                id="fullscreenBtn"
                class="btn-fullscreen control-icon-layout"
                title="Fullscreen"
              >
                <span class="icon-fullscreen-enter"></span>
              </div>
              <div
                id="bitrateListBtn"
                class="control-icon-layout"
                title="Bitrate List"
              >
                <span class="icon-bitrate"></span>
              </div>
              <input
                type="range"
                id="volumebar"
                class="volumebar"
                value="0"
                min="0"
                max="1"
                step=".01"
              />
              <div id="muteBtn" class="btn-mute control-icon-layout" title="Mute">
                <span id="iconMute" class="icon-mute-off"></span>
              </div>
              <div
                id="trackSwitchBtn"
                class="control-icon-layout"
                title="A/V Tracks"
              >
                <span class="icon-tracks"></span>
              </div>
              <div
                id="captionBtn"
                class="btn-caption control-icon-layout"
                title="Closed Caption"
              >
                <span class="icon-caption"></span>
              </div>
              <span id="videoDuration" class="duration-display">
                00:00:00
              </span>
              <div class="seekContainer">
                <div id="seekbar" class="seekbar seekbar-complete">
                  <div id="seekbar-buffer" class="seekbar seekbar-buffer"></div>
                  <div id="seekbar-play" class="seekbar seekbar-play"></div>
                </div>
              </div>
              <div id="thumbnail-container" class="thumbnail-container">
                <div id="thumbnail-elem" class="thumbnail-elem"></div>
                <div id="thumbnail-time-label" class="thumbnail-time-label"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CustomVideoPlayer;