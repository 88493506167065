import { useSelector } from "react-redux";
import AppContainer from "../util/AppContainer";
import "./locationSelector.css";
import GPSLocator from "./GPSLocator";
import RecentSearches from "./RecentSearches";
import LocationSearch from "./LocationSearch";
import { useRef, useState } from "react";
import { Spin } from "antd";

const LocationSelector = (props) => {
  const { locationSelector } = props;
  const recentSearchesRef = useRef();
  const [loading, setLoading] = useState(false);
  const [isLocationManuallySelected, setIsLocationManuallySelected] = useState(false);
  const isAddAddressActive = useSelector(
    (state) => state.location.isAddAddressActive
  );

  const handleCurrentLocationDetected = (location) =>{
    recentSearchesRef.current.autoAdressSelect(location)
  }

  if(!locationSelector) {
    return <>
    <LocationSearch from={props.from} locationSelector={locationSelector} initialRender={true} />
        <GPSLocator from={props.from} locationSelector={locationSelector} initialRender={true} setIsLocationManuallySelected={setIsLocationManuallySelected} isLocationManuallySelected={isLocationManuallySelected} setLoading={setLoading} handleCurrentLocationDetected={handleCurrentLocationDetected} />
        <RecentSearches initialRender={true} ref={recentSearchesRef}  from={props.from} setLoading={setLoading} setIsLocationManuallySelected={setIsLocationManuallySelected} />
    </>
  }

  return (
    <AppContainer>
      <div style={{ position: 'relative', height:"100%" }}>
        {loading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000,
            }}
          >
            <div style={{ color: '#fff' }}>
            <Spin />
            </div>
          </div>
        )}
        <LocationSearch from={props.from} locationSelector={locationSelector} initialRender={false} />
        <GPSLocator from={props.from} locationSelector={locationSelector} initialRender={false} setLoading={setLoading} setIsLocationManuallySelected={setIsLocationManuallySelected} handleCurrentLocationDetected={handleCurrentLocationDetected} />
        {!isAddAddressActive && <RecentSearches initialRender={false} ref={recentSearchesRef}  from={props.from} setLoading={setLoading} setIsLocationManuallySelected={setIsLocationManuallySelected} />}
      </div>
    </AppContainer>
  );
};

export default LocationSelector;
