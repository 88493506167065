import "./recent-searches.css";
import HistoryIcon from "@material-ui/icons/History";
import { useDispatch, useSelector } from "react-redux";
import { loginActions } from "../../store/loginSlice";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { API_TEST, FREE_DILIVERY_DISTANCE_RANGE, MINIMUM_AMOUNT_MAX_RANGE, MINIMUM_AMOUNT_MIN_RANGE, ORDER_DISTANCE_RANGE, ORDER_DISTANCE_RANGE_MIN, PIDGE_API_TOKEN, PIDGE_API_URL, SHADOWFAX_API_TOKEN, SHADOWFAX_API_URL } from "../../store/constants";
import { cartAction } from "../../store/cartSlice";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { uniqBy } from "lodash";
import axios from "axios";
import { extractPincode } from "../../utils/helper";

const RecentSearches = forwardRef((props, ref) => {
  const { setLoading, initialRender, setIsLocationManuallySelected } = props;
  const locationSelector = useSelector(
    (state) => state.bottomBar.locationSelector
  );
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const selectedAddress = useSelector((state) => state.auth.selectedAddress);
  const addresses = user.addressLine1;
  const chefList = useSelector((state) => state.chef.chefList);
  const defaultChefLocation = chefList.find((c) => c?.chefLocation)?.chefLocation;

  const sourceLocation = defaultChefLocation || {
    lat: 19.116606,
    lng: 72.8854982,
  };

  const switchTab = (tabName) => {
    if (tabName === "chef") {
      if (!!locationSelector) {
        dispatch(bottomBarActions.loadTab({ tabName: tabName }));
      }
    } else {
      dispatch(bottomBarActions.loadTab({ tabName: tabName }));
    }


    if (props.from) {
      dispatch(bottomBarActions.updateFrom({ from: "" }));
    }
  };

  const distanceMatrixRef = useRef(null);

  const checkShadowfaxDeliveryServiceable = async (requestBody) => {
    try {
      const token = SHADOWFAX_API_TOKEN; // Your token
      const apiUrl = `${SHADOWFAX_API_URL}serviceability/`;
  
      const config = {
        headers: {
          Authorization: `${token}`
        }
      };
      const response = await axios.post(apiUrl, requestBody, config);
      const deliveryValue = response.data;
      if(deliveryValue.is_serviceable){
        dispatch(loginActions.setAvailableDeliveryOptions({type: "SHADOWFAX", payload: deliveryValue} ));
      }else{
        dispatch(loginActions.setAvailableDeliveryOptions({type: "SHADOWFAX", payload: {}} ));
      }
    } catch (error) {
      dispatch(loginActions.setAvailableDeliveryOptions({type: "SHADOWFAX", payload: {}} ));
    }
  };

  const checkDeliveryServiceable = async (requestBody) => {
    try {
      const token = PIDGE_API_TOKEN;
      const apiUrl = `${PIDGE_API_URL}/v1.0/store/channel/vendor/quote`;

      const config = {
        headers: {
          Authorization: `${token}`
        }
      };

      const response = await axios.post(apiUrl, requestBody, config);
      const deliveryOptions = response.data.data.items.filter(response =>
        response.pickup_now &&
        response.quote.eta.pickup &&
        response.quote.eta.drop
      );;
      dispatch(loginActions.setAvailableDeliveryOptions({type: "PIDGE", payload: deliveryOptions} ));
    } catch (error) {
      dispatch(loginActions.setAvailableDeliveryOptions({type: "PIDGE", payload: []}));
    }
  };

  const checkPorterDeliveryServiceable = async (porterBody) => {
    try {
      const apiUrl = `${API_TEST}porter/orders/get_quote`;

      const response = await axios.post(apiUrl, porterBody);
      const deliveryOptions = response.data.data.vehicles.filter(response =>
        response.type === "2 Wheeler"
      );;
      dispatch(loginActions.setAvailableDeliveryOptions({type: "PORTER", payload: deliveryOptions} ));
    } catch (error) {
      dispatch(loginActions.setAvailableDeliveryOptions({type: "PORTER", payload: []}));
    }
  };

  const onSelectAddressHandler = (data, autoDetect) => {
    setLoading(true)
    // console.log("DATA :", data.location);
    const uniqKitchenData = uniqBy(chefList, obj => obj.kitchenId);
    uniqKitchenData.filter((a) => a.kitchenId).map((kitchen) => {
      const kitchenId = kitchen.kitchenId;
      const chefLocation = kitchen.chefLocation;
      distanceMatrixRef.current = new window.google.maps.DistanceMatrixService();
      distanceMatrixRef.current.getDistanceMatrix(
        {
          origins: [chefLocation],
          destinations: [
            {
              lat: data?.location.lat,
              lng: data?.location.lng,
            },
          ],
          travelMode: "DRIVING",
        },
        (res, status) => {
          if (status !== "OK") {
            console.log("Unable to fetch...");
            setLoading(false)
          } else {
            let delivery_distance = res.rows[0].elements[0].distance.value;
            let delivery_duration = res.rows[0].elements[0].duration;
            let deliveryObject = {
              delivery_distance,
              delivery_duration,
              kitchenLocation: chefLocation
            };

            dispatch(loginActions.setDeliveryDetails({ kitchenId, payload: deliveryObject }));
            let deliverydeliveryDistanceInKMS = Math.round(
              delivery_distance / 1000
            );

            const requestBody = {
              "pickup": {
                "coordinates": {
                  "latitude": chefLocation.lat,
                  "longitude": chefLocation.lng
                },
                "pincode": extractPincode(res.originAddresses[0])
              },
              "drop": [
                {
                  "ref": "4324324",
                  "location": {
                    "coordinates": {
                      "latitude": data?.location.lat,
                      "longitude": data?.location.lng
                    },
                    "pincode": extractPincode(res.destinationAddresses[0])
                  },
                  "attributes": {
                    "cod_amount": 0,
                    "weight": 500,
                    "volumetric_weight": 225
                  }
                }
              ]
            }
            const porterBody = {
              "pickup_details": {
                "lat": chefLocation.lat,
                "lng": chefLocation.lng
              },
              "drop_details": {
                "lat": data?.location.lat,
                "lng": data?.location.lng
              },
              "customer": {
                "name": "Ashwin",
                "mobile": {
                  "country_code": "+91",
                  "number": "9664257767"
                }
              }
            }

            const srequestBody = {
              pickup_details: {
                building_name: "",
                latitude: chefLocation.lat,
                longitude: chefLocation.lng,
                address: res.originAddresses[0]
              },
              drop_details: {
                building_name: "",
                latitude: data?.location.lat,
                longitude: data?.location.lng,
                address: res.destinationAddresses[0]
              }
            };
            checkDeliveryServiceable(requestBody)
            checkShadowfaxDeliveryServiceable(srequestBody)
            checkPorterDeliveryServiceable(porterBody)
            if (deliverydeliveryDistanceInKMS > ORDER_DISTANCE_RANGE) {
              dispatch(loginActions.setIsServiceable(false));
              dispatch(loginActions.setIsServiceableAlert(true));
              dispatch(loginActions.setUnserviceableModalOnCart(true));
              dispatch(loginActions.setMinimumAmount(0));
            } else if (deliverydeliveryDistanceInKMS > ORDER_DISTANCE_RANGE_MIN &&
              deliverydeliveryDistanceInKMS <= ORDER_DISTANCE_RANGE) {
              dispatch(loginActions.setIsServiceable(true));
              dispatch(loginActions.setIsServiceableAlert(false));
              dispatch(loginActions.setFarAwayModalOnCart(true));
              dispatch(loginActions.setMinimumAmount(MINIMUM_AMOUNT_MAX_RANGE));
            } else if (deliverydeliveryDistanceInKMS > FREE_DILIVERY_DISTANCE_RANGE &&
              deliverydeliveryDistanceInKMS < ORDER_DISTANCE_RANGE_MIN) {
              dispatch(loginActions.setIsServiceable(true));
              dispatch(loginActions.setIsServiceableAlert(false));
              dispatch(loginActions.setMinimumAmount(MINIMUM_AMOUNT_MIN_RANGE));
            } else {
              dispatch(loginActions.setIsServiceable(true));
              dispatch(loginActions.setIsServiceableAlert(false));
              dispatch(loginActions.setMinimumAmount(0));
            }

            let coords = { lat: data.location.lat, lng: data.location.lng };
            dispatch(loginActions.setSelectedAddress(data));
            dispatch(loginActions.setLocation(data.location.locationName));
            dispatch(cartAction.setIsAddressOptionsVisible(false));
            dispatch(loginActions.setCoords(coords));
            if (!autoDetect) {
              props.from ? switchTab(props.from) : switchTab("chef");
            }
            setLoading(false)
          }
        }
      );
    })
  };

  useImperativeHandle(ref, () => ({
    autoAdressSelect(locationData) {
      onSelectAddressHandler(locationData, true)
    }
  }));

  if (initialRender) {
    return <></>
  }

  return (
    <>
      {addresses.length ? (
        <div className="recent-searches_wrapper">
          <h6>Saved address</h6>
          {addresses.length
            ? addresses.map((data) => {
              return (
                <div
                  key={data._id}
                  className="recent-searches_container"
                  onClick={() => {
                    setIsLocationManuallySelected(true)
                    onSelectAddressHandler(data, false)
                  }}>
                  <HistoryIcon />
                  <div className="recent-address_container">
                    <h6>{data.tag}</h6>
                    <p>
                      {data.location.locationName.length >= 70
                        ? `${data.location.locationName.slice(0, 70)}...`
                        : data.location.locationName}
                    </p>
                  </div>
                </div>
              );
            })
            : null}
        </div>
      ) : null}
    </>
  );
});

export default RecentSearches;
