import moment from 'moment';

function isBefore4AM(timeStr) {
  const start = moment('12:00 AM', 'hh:mm A');
  const end = moment('4:00 AM', 'hh:mm A');
  const checkTime = moment(timeStr, 'hh:mm A');

  return checkTime.isBetween(start, end, null, '[]');
}

const getNextObjectByIndex = (arr = [], index) => {
  if (arr.length === 0) {
    return null; // Handle empty array
  }

  if (index < arr.length - 1) {
    // If the index is not the last, return the next object
    return arr[index + 1];
  } else {
    // If the index is the last, return the first object
    return arr[0];
  }
}

export const getTodayDateValue = (schedules) => {
  const currentDayOfWeek = moment().subtract({ 'hours': 4 }).day();
  const currentTime = moment();
  const isMidnightTime = isBefore4AM(moment().format("hh:mm A"))

  const todaySchedule = schedules?.find((schedule) => {
    const scheduleDayOfWeek = moment(schedule.dayOfWeek, 'dddd').day();

    return scheduleDayOfWeek === currentDayOfWeek;
  });
  if (isMidnightTime && !todaySchedule?.online) {
    const index = schedules?.findIndex(a => a?._id === todaySchedule?._id)
    return getNextObjectByIndex(schedules || [], index)
  } else {
    return todaySchedule || null;
  }
};

function formatTime(originalTime) {  
  let time = originalTime.toLowerCase().replace("00", "");

  if (time.endsWith("am") || time.endsWith("pm")) {
    time = time.replace("am", "am").replace("pm", "pm");
  }
  if(originalTime.includes(":00")){
    return time.replace(":", "")
  }
  return time;
}

export const getDisplayChefTimeLabel = (todayChefSchedule) => {
  const { isAvailable } = todayChefSchedule || {};
  if(!isAvailable) return "Late night delivery"
  const startTime = todayChefSchedule?.startTime ? todayChefSchedule?.startTime?.replace(" ", "").toLowerCase() : todayChefSchedule?.startTime1?.replace(" ", "").toLowerCase() ;
  const endTime = todayChefSchedule?.endTime ?  todayChefSchedule?.endTime?.replace(" ", "").toLowerCase() : todayChefSchedule?.endTime1?.replace(" ", "").toLowerCase() ;
  if(!startTime || !endTime) return "Not available"
  const formattedStartTime = formatTime(startTime);
  const formattedEndTime = formatTime(endTime);
  const textOutput = `${formattedStartTime} to ${formattedEndTime}`;
  return textOutput

}

export const secondShiftApplies =(schedule) => {
  // Get current date and time
  const now = moment();
  
  // Get current day of the week
  const currentDayOfWeek = now.format('dddd');

  // Check if today matches the schedule's dayOfWeek
  if (currentDayOfWeek !== schedule.dayOfWeek) {
      return true;
  }

  // Convert start and end times to moment objects
  const startTime = moment(schedule.startTime, 'hh:mm A');
  const endTime = moment(schedule.endTime, 'hh:mm A');
  const startTime1 = moment(schedule.startTime1, 'hh:mm A');
  const endTime1 = moment(schedule.endTime1, 'hh:mm A');

  // Check if current time is not between startTime and endTime, and also after endTime
  if(!moment(now).isBetween(startTime, endTime)){
    return true
  }
  
  return false;
}

export const formatScheduleTime = (schedules) => {
  const schedule = schedules.map((time) => {
    const { dayOfWeek, startTime : startTime1, startTime1 : startTime2, endTime : endTime1, endTime1 : endTime2, isAvailable } = time;
    const startTime = startTime1 || startTime2;
    const endTime = endTime1 || endTime2;
    if (startTime && endTime) {
      const endTimeParsed = new Date(`2000-01-01 ${endTime}`);
      const startTimeParsed = new Date(`2000-01-01 ${startTime}`);
      const timeText = `${startTime.toLowerCase()} – ${endTime.toLowerCase()}`;
      const timeText1 = startTime2 && endTime2 ?  `, ${startTime2?.toLowerCase()} – ${endTime2?.toLowerCase()}` : "";
      if (endTimeParsed < startTimeParsed) {
        return { day: `${dayOfWeek.slice(0, 3)}`, time: timeText + timeText1 , isAvailable };
      }

      return { day: `${dayOfWeek.slice(0, 3)}`, time: timeText + timeText1, isAvailable }
    } else {
      return { day: `${dayOfWeek.slice(0, 3)}`, time: `Not available`, isAvailable };
    }
  })
  return schedule
}

export const getDishBandLabel = (prepTime) => {
  if (prepTime < 40) {
    return "HIDDEN_GEM"
  } else if (prepTime < 60) {
    return "MIDDLE"
  } else if (prepTime < 80) {
    return "TRENDING"
  } else {
    return "NORMAL"
  }
}