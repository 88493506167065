import React, { useEffect } from 'react';
import Sheet from 'react-modal-sheet';
import "./vegChoiceModal.css"
import InstallEmoji from '../../assets/ReactSvg/InstallEmoji';
import { Spin } from 'antd';


const LocationChoiceModal = ({ isOpen, setOpen, loading, isLocationValid, manualLocationClick, onGeoLocationClickHandler }) => {

    const onCloseModal = () => {
        setOpen(false)
    }

    useEffect(() =>{
        if(!loading && isLocationValid){
            setOpen(false)
        }
    },[loading, isLocationValid])
   
    return (
        <Sheet
            springConfig={{
                stiffness: 300,
                damping: 30,
                mass: 0.2
            }}
            isOpen={isOpen}
            onClose={onCloseModal}
            detent="content-height"
            className='install-modal-popup vegchoice-modal-sheet customReactSheet'
            onSnap={(snapIndex) =>
                console.log("> Current snap point index:", snapIndex)
            }
        >
            <Sheet.Container>
                <Sheet.Content>
                    <div className='install-modal-wrapper'>
                        <div className='install-modal-emogy'>
                            <InstallEmoji />
                        </div>
                        <h5 style={{ paddingTop: 0 }} className='install-app-label'>Select location option</h5>
                        <div style={{ display: "flex", flexDirection: "column", columnGap: 10, marginTop: 15 }}>
                            <button
                                onClick={() => onGeoLocationClickHandler(true)}
                                style={{ background: "#7d9a6f", marginTop: 10, borderColor: "#7d9a6f" }} className='download-now-button-modal'>Allow location</button>
                            <button
                                onClick={() => {
                                    manualLocationClick()
                                }}
                                className='enter-location-manually-link'>Enter location manually</button>
                        </div>
                    </div>
                    {loading && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 1000,
                            }}
                        >
                            <div style={{ color: '#fff' }}>
                                <Spin />
                            </div>
                        </div>
                    )}
                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop onClick={onCloseModal} />
        </Sheet>
    )
}

export default LocationChoiceModal