import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "./chefListScreen.css"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BottomBar from '../BottomBar';
import { fetchChefList, fetchKitchensList, getKitchenStatus } from '../../store/chefActions';
import ChefCardComponent from './ChefCardComponent';

const ChefListScreen = () => {
  const dispatch = useDispatch();
  const chefList = useSelector((state) => state.chef.chefList);
  const chefBottomScreen = useSelector((state) => state.bottomBar.chef);
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [videoLoadedIndex, setVideoLoadedIndex] = useState(0);
  const chefListRef = useRef(null);

  useEffect(() => {
    dispatch(fetchChefList());
    dispatch(fetchKitchensList());
    dispatch(getKitchenStatus());
  }, [dispatch]);

  useEffect(() => {
    if(chefBottomScreen && !componentLoaded){
      setComponentLoaded(true)
    }
    
  }, [chefBottomScreen]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 10);

    return () => clearTimeout(timer);
  }, []);

  const videosOfChef = ["105/Chef-display-video-2.mp4",
    "102/Chef-display-video.mp4",
    "103/Chef-display-video-1.mp4",
    "104/Chef-display-video-3-1.mp4",
    "105/Chef-display-video-2.mp4",
    "106/Sonal-display-video.mp4",
    "105/Chef-display-video-2.mp4",
    "103/Chef-display-video-1.mp4",
    "104/Chef-display-video-3-1.mp4"]

  
  return (
    <div>
      {!!componentLoaded && <div className={`chefListScreenContainerWrapper ${visible ? 'open' : ''}`}>
        <div className="chefListScreenContainer">
          <div
            className={`chefListScreenComponent`}
            ref={chefListRef}
            style={{ padding: 15, paddingTop: 32 }}
          >
            {chefList.map((chef, index) => {
              return <ChefCardComponent chef={chef} chefCardComponentIndex={index} setVideoLoadedIndex={setVideoLoadedIndex} videoLoadedIndex={videoLoadedIndex} chefVideo={videosOfChef[index]} isChefMainScreen={false} />
            })}
          </div>
        </div>
      </div>}
      <BottomBar isExplore />
    </div>
  )
}

export default ChefListScreen