import React, { useState, useEffect, useRef } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Polyline,
  Marker,
} from "react-google-maps";
import API_KEY from "./apiKey";
import axios from "axios";
import { getLatLngArray, getNextDirection } from "./mapHelper";
import { API_TEST, PIDGE_API_TOKEN, PIDGE_API_URL, SHADOWFAX_API_TOKEN, SHADOWFAX_API_URL } from "../../store/constants";
import { useSelector } from "react-redux";
import "./mapTracking.css"

const car =
  "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";
const icon = {
  path: car,
  scale: 0.7,
  strokeColor: "white",
  strokeWeight: 0.1,
  fillOpacity: 1,
  fillColor: "#404040",
  offset: "5%",
  rotation: 240,
};

const Map = ({ deliveryAddress }) => {
  const [prevKitchenLocation, setPrevKitchenLocation] = useState(null);
  const [prevOrderAddress, setPrevOrderAddress] = useState(null);
  const activeOrderDetails = useSelector((state) => state.auth.orderDetails);

  const singleOrderHistoryDetails = useSelector((state) => state.auth.singleOrderHistoryDetails);
  const selectedOrderDetails = !!singleOrderHistoryDetails && Object.keys(singleOrderHistoryDetails).length !== 0 ? singleOrderHistoryDetails : activeOrderDetails;

  const orderDetails = useSelector((state) => state.auth.orderDetails);
  const orderDeliveryPortal = useSelector((state) => state.auth.orderDetails?.selectedDeliveryPortal);
  const orderDetailAddress = useSelector(
    (state) => state.auth?.orderDetails?.address
  );
  const chefList = useSelector((state) => state.chef.chefList);
  const defaultChefLocation = chefList.find((c) => c?.chefLocation)?.chefLocation;
  const orderAddress = deliveryAddress || orderDetailAddress
  const [path, setPath] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [stops, setStops] = useState([]);
  const [routeLoading, setRouteLoading] = useState(true);
  const [position, setPosition] = useState(null);
  const [progress, setProgress] = useState([]);
  const [counter, setCounter] = useState(1);
  const [nextLine, setNextLine] = useState({});

  const sourceLocation = defaultChefLocation || {
    lat: 19.116606,
    lng: 72.8854982,
  };

  const kitchenLocationObj = { lat: selectedOrderDetails?.lat, lng: selectedOrderDetails?.lng };

  const kitchenLocation = selectedOrderDetails?.lat ? kitchenLocationObj : sourceLocation

  const velocity = 100;
  const initialDate = new Date();

  const getDistance = () => {
    const differentInTime = (new Date() - initialDate) / 1000;
    return differentInTime * velocity;
  };

  const getDirections = async () => {
    if (!kitchenLocation?.lat || !orderAddress.location) return;
    setRouteLoading(true);
    const origin = `${kitchenLocation?.lat},${kitchenLocation?.lng}`;
    const destination = `${orderAddress.location?.lat},${orderAddress.location?.lng}`;

    try {
      const response = await axios.get(
        `${API_TEST}getDirections/${origin}/${destination}`
      );
      const routeResponse = response.data.data.routes[0].legs[0].steps;
      setRouteList(response.data.data.routes);
      setStops([
        { lat: +origin.split(",")[0], lng: +origin.split(",")[1], id: "stop1" },
        {
          lat: +destination.split(",")[0],
          lng: +destination.split(",")[1],
          id: "stop2",
        },
      ]);
      calculatePath(getLatLngArray(routeResponse));
      setRouteLoading(false);
    } catch (error) {
      setRouteLoading(false);
      // Handle error if needed
    }
  };

  const moveObjectShadowfax = async () => {
    const distance = getDistance();
    if (!distance) {
      return;
    }

    let progress = path.filter(
      (coordinates) => coordinates.distance < distance
    );
    const orderId = orderDetails.orderRef;
    try {

      const apiUrl = `${SHADOWFAX_API_URL}track/${orderId}/`; // Your token
      const token = SHADOWFAX_API_TOKEN

      const config = {
        headers: {
          Authorization: `${token}`
        }
      };
      const response = await axios.get(apiUrl, config);
      const jsonData = response.data;
      const rider_latitude = jsonData.rider_latitude || "";
      const rider_longitude = jsonData.rider_longitude || "";
      const driverLocation = { lat: rider_latitude, lng: rider_longitude }
      const origin = `${kitchenLocation?.lat},${kitchenLocation?.lng}`;
      const destination = `${driverLocation?.lat},${driverLocation?.lng}`;
      const responseDirection = await axios.get(
        `${API_TEST}getDirections/${origin}/${destination}`
      );
      const distance = getNextDirection(responseDirection, driverLocation?.lat, driverLocation?.lng)
      const nextLine = { distance, lat: driverLocation?.lat, lng: driverLocation?.lng }
      setNextLine(nextLine)
      if (!nextLine) {
        setProgress(progress);
        setCounter(0);
        return;
      }

      const lastLine = progress[progress.length - 1];
      const lastLineLatLng = new window.google.maps.LatLng(
        lastLine.lat,
        lastLine.lng
      );
      const nextLineLatLng = new window.google.maps.LatLng(
        nextLine.lat,
        nextLine.lng
      );

      const totalDistance = nextLine.distance - lastLine.distance;
      const percentage = (distance - lastLine.distance) / totalDistance;

      const newPosition = window.google.maps.geometry.spherical.interpolate(
        lastLineLatLng,
        nextLineLatLng,
        percentage
      );

      progress = progress.concat(newPosition);
      setPosition(newPosition);
      setProgress(progress);
    } catch (error) { }
  };

  const moveObjectPidge = async () => {
    const distance = getDistance();
    if (!distance) {
      return;
    }

    let progress = path.filter(
      (coordinates) => coordinates.distance < distance
    );
    const pidge_order_id = orderDetails.pidge_order_id;
    try {
      const apiUrl = `${PIDGE_API_URL}/v1.0/store/channel/vendor/order/${pidge_order_id}/fulfillment/tracking`;
      const token = PIDGE_API_TOKEN

      const config = {
        headers: {
          Authorization: `${token}`
        }
      };
      const response = await axios.get(apiUrl, config);
      const riderLocation = response.data.data.location;
      const rider_latitude = riderLocation.latitude || "";
      const rider_longitude = riderLocation.longitude || "";
      const driverLocation = { lat: rider_latitude, lng: rider_longitude }
      const origin = `${kitchenLocation?.lat},${kitchenLocation?.lng}`;
      const destination = `${driverLocation?.lat},${driverLocation?.lng}`;
      const responseDirection = await axios.get(
        `${API_TEST}getDirections/${origin}/${destination}`
      );
      const distance = getNextDirection(responseDirection, driverLocation?.lat, driverLocation?.lng)
      const nextLine = { distance, lat: driverLocation?.lat, lng: driverLocation?.lng }
      setNextLine(nextLine)
      if (!nextLine) {
        setProgress(progress);
        setCounter(0);
        return;
      }

      const lastLine = progress[progress.length - 1];
      const lastLineLatLng = new window.google.maps.LatLng(
        lastLine.lat,
        lastLine.lng
      );
      const nextLineLatLng = new window.google.maps.LatLng(
        nextLine.lat,
        nextLine.lng
      );

      const totalDistance = nextLine.distance - lastLine.distance;
      const percentage = (distance - lastLine.distance) / totalDistance;

      const newPosition = window.google.maps.geometry.spherical.interpolate(
        lastLineLatLng,
        nextLineLatLng,
        percentage
      );

      progress = progress.concat(newPosition);
      setPosition(newPosition);
      setProgress(progress);
    } catch (error) { }
  };

  const moveObjectPorter = async () => {
    const distance = getDistance();
    if (!distance) {
      return;
    }

    let progress = path.filter(
      (coordinates) => coordinates.distance < distance
    );
    const pidge_order_id = orderDetails.pidge_order_id;
    try {
      const apiUrl = `${API_TEST}porter/orders/${pidge_order_id}`;
      const response = await axios.get(apiUrl);
      const jsonData = response.data.data;
      const riderLocation = jsonData?.partner_info?.location;
      const driverLocation = riderLocation;
      const origin = `${kitchenLocation?.lat},${kitchenLocation?.lng}`;
      const destination = `${driverLocation?.lat},${driverLocation?.lng}`;
      const responseDirection = await axios.get(
        `${API_TEST}getDirections/${origin}/${destination}`
      );
      const distance = getNextDirection(responseDirection, driverLocation?.lat, driverLocation?.lng)
      const nextLine = { distance, lat: driverLocation?.lat, lng: driverLocation?.lng }
      setNextLine(nextLine)
      if (!nextLine) {
        setProgress(progress);
        setCounter(0);
        return;
      }

      const lastLine = progress[progress.length - 1];
      const lastLineLatLng = new window.google.maps.LatLng(
        lastLine.lat,
        lastLine.lng
      );
      const nextLineLatLng = new window.google.maps.LatLng(
        nextLine.lat,
        nextLine.lng
      );

      const totalDistance = nextLine.distance - lastLine.distance;
      const percentage = (distance - lastLine.distance) / totalDistance;

      const newPosition = window.google.maps.geometry.spherical.interpolate(
        lastLineLatLng,
        nextLineLatLng,
        percentage
      );

      progress = progress.concat(newPosition);
      setPosition(newPosition);
      setProgress(progress);
    } catch (error) { }
  };

  const calculatePath = (paths) => {
    const filteredPaths = paths.map((coordinates, i, array) => {
      if (i === 0) {
        return { ...coordinates, distance: 0 }; // it begins here!
      }
      const { lat: lat1, lng: lng1 } = coordinates;
      const latLong1 = new window.google.maps.LatLng(lat1, lng1);

      const { lat: lat2, lng: lng2 } = array[0];
      const latLong2 = new window.google.maps.LatLng(lat2, lng2);

      // in meters:
      const distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          latLong1,
          latLong2
        );

      return { ...coordinates, distance };
    });
    setPath(filteredPaths);
  };

  useEffect(() => {
    const shouldFetchDirections =
      (kitchenLocation && JSON.stringify(kitchenLocation) !== JSON.stringify(prevKitchenLocation)) ||
      (orderAddress && JSON.stringify(orderAddress) !== JSON.stringify(prevOrderAddress));

    if (shouldFetchDirections) {
      getDirections();
    }

    setPrevKitchenLocation(kitchenLocation);
    setPrevOrderAddress(orderAddress);
  }, [kitchenLocation, orderAddress, prevKitchenLocation, prevOrderAddress]);

  useEffect(() => {
    if (!path.length) return;
    // Set up the interval to increment the counter every 1000ms
    const interval = setInterval(() => {
      if (counter !== 0) {
        setCounter((prevCounter) => prevCounter + 1);
        switch (orderDeliveryPortal) {
          case "PIDGE":
            moveObjectPidge();
            break;
          case "SHADOWFAX":
            moveObjectShadowfax()
            break;
          case "PORTER":
            moveObjectPorter()
            break;
          default:
            break;
        }

      }
    }, 5000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [path]);

  useEffect(() => {
    const distance = getDistance();
    if (!distance || !path.length) {
      return;
    }

    let progress = path.filter(
      (coordinates) => coordinates.distance < distance
    );

    // const nextLine = path.find(
    //   (coordinates) => coordinates.distance > distance
    // );

    let point1, point2;
    if (nextLine) {
      point1 = progress[progress.length - 1];
      point2 = nextLine;
    } else {
      // it's the end, so use the latest 2
      point1 = progress[progress.length - 2];
      point2 = progress[progress.length - 1];
    }

    const point1LatLng = new window.google.maps.LatLng(point1.lat, point1.lng);
    const point2LatLng = new window.google.maps.LatLng(point2.lat, point2.lng);

    const angle = window.google.maps.geometry.spherical.computeHeading(
      point1LatLng,
      point2LatLng
    );
    const actualAngle = angle - 90;

    const markerUrl =
      "https://i.ibb.co/Jp2pCQr/freesvgorg27380-removebg.png";
    const marker = document.querySelector(`[src="${markerUrl}"]`);

    if (marker) {
      // when it hasn't loaded, it's null
      marker.style.transform = `rotate(${actualAngle}deg)`;
    }
  }, [position, path]);

  const icon1 = {
    url: "https://i.ibb.co/Jp2pCQr/freesvgorg27380-removebg.png",
    scaledSize: new window.google.maps.Size(30, 30),
    anchor: new window.google.maps.Point(15, 15),
    scale: 0.7,
  };
  const lat = !!position ? position.lat() : path[0]?.lat;
  const lng = !!position ? position.lng() : path[0]?.lng;

  return (
    <>
      {routeLoading || !routeList.length || !path.length ? (
        <span>Loading...</span>
      ) : (
        <GoogleMap
          options={{
            disableDefaultUI: true,
            gestureHandling: "greedy",
            keyboardShortcuts: false,
            clickableIcons: false,
            noClear: false
          }}
          defaultZoom={16}
          defaultCenter={{
            lat: lat,
            lng: lng,
          }}
        >
          <Polyline
            traffic={new window.google.maps.TrafficLayer()}
            path={path}
            options={{
              strokeColor: "#0088FF",
              strokeWeight: 6,
              strokeOpacity: 0.6,
            }}
          />

          {stops.map((stop, index) => (
            <Marker
              key={stop.id}
              position={{ lat: stop.lat, lng: stop.lng }}
              title={stop.id}
              label={`${index + 1}`}
            />
          ))}

          {progress && (
            <>
              <Polyline path={progress} options={{ strokeColor: "pink" }} />

              <Marker icon={icon1} position={progress[progress.length - 1]} />
            </>
          )}
        </GoogleMap>
      )}
    </>
  );
};

const MemoizedMap = React.memo(Map);

const MapComponent = withScriptjs(withGoogleMap(MemoizedMap));
const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${API_KEY.mapsOtherkey}`;

export default ({ deliveryAddress }) => (
  <div>
    <MapComponent
      googleMapURL={mapURL}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `400px`, width: "100%" }} />}
      mapElement={<div style={{ height: `100%` }} />}
      deliveryAddress={deliveryAddress}
    />
  </div>
);
