import React from "react";

function RightOutlinedSvg() {
  return (
    <svg height={25} xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 100 125">
      <path d="M51 49.9L24.4 23.4c-4.2-4.2-4.2-11 0-15.2 4.2-4.2 11-4.2 15.3 0L77 45.4c2.5 2.5 2.5 6.6 0 9.2L39.6 91.7c-4.2 4.2-11 4.2-15.3 0-4.2-4.2-4.2-11 0-15.2L51 49.9z"></path>
    </svg>
  );
}

export default RightOutlinedSvg;