import * as React from "react";
const LeafIconSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    viewBox="0 0 6 8"
    {...props}
  >
    <path
      stroke="#A91313"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.75}
      d="m5.388 1.426.187 1.944c.121 1.262-.825 2.385-2.086 2.506-1.238.12-2.36-.785-2.478-2.023a2.252 2.252 0 0 1 2.025-2.457l2.073-.2c.14-.013.265.09.279.23Z"
    />
    <path
      stroke="#A91313"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.75}
      d="M1.795 6.805s.45-1.87 1.5-3"
    />
  </svg>
);
export default LeafIconSvg;
