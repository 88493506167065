import React from "react";

function PlaystoreSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      x="0"
      y="0"
      viewBox="0 0 48 48"
    >
      <linearGradient
        id="AraffhWwwEqZfgFEBZFoqa_L1ws9zn2uD01_gr1"
        x1="18.102"
        x2="25.297"
        y1="3.244"
        y2="34.74"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#35ab4a"></stop>
        <stop offset="0.297" stopColor="#31a145"></stop>
        <stop offset="0.798" stopColor="#288739"></stop>
        <stop offset="1" stopColor="#237a33"></stop>
      </linearGradient>
      <path
        fill="url(#AraffhWwwEqZfgFEBZFoqa_L1ws9zn2uD01_gr1)"
        d="M13.488 4.012c-2.694-1.504-5.883-.234-7.038 2.311L24.126 24l9.014-9.014L13.488 4.012z"
      ></path>
      <linearGradient
        id="AraffhWwwEqZfgFEBZFoqb_L1ws9zn2uD01_gr2"
        x1="19.158"
        x2="21.194"
        y1="23.862"
        y2="66.931"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#f14e5d"></stop>
        <stop offset="0.499" stopColor="#ea3d4f"></stop>
        <stop offset="1" stopColor="#e12138"></stop>
      </linearGradient>
      <path
        fill="url(#AraffhWwwEqZfgFEBZFoqb_L1ws9zn2uD01_gr2)"
        d="M33.14 33.014L24.126 24 6.45 41.677c1.156 2.546 4.345 3.815 7.038 2.312L33.14 33.014z"
      ></path>
      <linearGradient
        id="AraffhWwwEqZfgFEBZFoqc_L1ws9zn2uD01_gr3"
        x1="32.943"
        x2="36.541"
        y1="14.899"
        y2="43.612"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ffd844"></stop>
        <stop offset="0.519" stopColor="#ffc63f"></stop>
        <stop offset="1" stopColor="#ffb03a"></stop>
      </linearGradient>
      <path
        fill="url(#AraffhWwwEqZfgFEBZFoqc_L1ws9zn2uD01_gr3)"
        d="M41.419 28.393c1.72-.96 2.58-2.676 2.581-4.393-.001-1.717-.861-3.434-2.581-4.393l-8.279-4.621L24.126 24l9.014 9.014 8.279-4.621z"
      ></path>
      <linearGradient
        id="AraffhWwwEqZfgFEBZFoqd_L1ws9zn2uD01_gr4"
        x1="13.853"
        x2="15.572"
        y1="5.901"
        y2="42.811"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.003" stopColor="#0090e6"></stop>
        <stop offset="1" stopColor="#0065a0"></stop>
      </linearGradient>
      <path
        fill="url(#AraffhWwwEqZfgFEBZFoqd_L1ws9zn2uD01_gr4)"
        d="M6.45 6.323A5.054 5.054 0 006 8.408v31.179c0 .761.164 1.463.45 2.09l17.674-17.68L6.45 6.323z"
      ></path>
    </svg>
  );
}

export default PlaystoreSvg;