import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    from: null,
    storieData: [{}],
    isOpen: false,
    isSwiped: false,
    swiperIndex: 0,
    runJoyride: true,
};

const storieSlice = createSlice({
    name: "Storie",
    initialState,
    reducers: {
        setStoryData(state, action) {
            state.isOpen = true;
            state.from = action.payload.from;
            state.storieData = action.payload.storieData;
            return state;
        },
        setStorieFalse(state) {
            state.isOpen = false;
            state.from = null;
            state.storieData = [];
            return state;
        },
        setSwiped(state, action) {
            state.isSwiped = action.payload.swiped;
            return state;
        },
        setSwiperIndex(state, action) {
            state.swiperIndex = action.payload;
            return state;
        },
        setRunJoyride(state, action) {
            state.runJoyride = action.payload;
            return state;
        }
    },
});

export const storieAction = storieSlice.actions;

export default storieSlice;
