import React from "react";

function DefaultReplyArrowSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 256 256"
    >
      <path
        fill="#000"
        strokeMiterlimit="10"
        strokeWidth="1"
        d="M1.2 28.321l14.931 8.62 14.931 8.62c1.599.923 3.599-.231 3.599-2.078V32.618C59.052 35.603 78.01 56.43 78.01 81.616c0 .985.799 1.784 1.784 1.784h8.423c.985 0 1.784-.799 1.784-1.784 0-31.804-24.321-58.03-55.341-61.068V9.003c0-1.847-1.999-3.001-3.599-2.078l-14.931 8.62L1.2 24.166c-1.6.923-1.6 3.232 0 4.155z"
        transform="matrix(2.81 0 0 2.81 1.407 1.407)"
      ></path>
    </svg>
  );
}

export default DefaultReplyArrowSvg;